import { useUser } from '@auth0/nextjs-auth0/client';
import Router from 'next/router';
import React from 'react';
import { Loading } from '@jux/ui/components';
import { PATHS } from '../../utils/paths';
import { HOC } from '../HOC.interface';

export const withGuestPageGuard: HOC =
  (Component: React.ElementType) => (props: any) => {
    // Don't let user into the dashboard unless he has created an organizations
    const { user, isLoading: userLoading } = useUser();

    if (userLoading) {
      return <Loading />;
    }

    // If user is logged in, redirect to dashboard
    if (user) {
      Router.push(PATHS.MAIN_PAGE);

      return null;
    }

    return <Component {...props} />;
  };
